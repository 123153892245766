<template>
  <span>
    <v-data-table 
      class="table-indicadores" 
      multi-sort 
      dense 
      :headers="headers" 
      :items="items" 
      :options="{ itemsPerPage: 10 }" 
      :headerProps="{ sortByText: 'Ordenar Por' }"
      no-data-text="Nenhum registro disponível"
      no-results-text="Nenhum registro correspondente encontrado"
      :mobile-breakpoint="10"
    >
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-tooltip bottom v-if="traducaoIndicadores[h.value] != null">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ h.text }}</span>
          </template>
          <span>{{ traducaoIndicadores[h.value] }}</span>
        </v-tooltip>
        <span v-else>{{ h.text }}</span>
      </template>
      <template v-slot:[`item.empresa`]="{ item }">
        {{ item.empresa.name | parseNumberToFloatBrIgnoringNaN }}
      </template>
      <template v-slot:[`item.psl`]="{ item }">
        <span :class="classIndicador(item.psl, item.metas.psl != undefined ? item.metas.psl : null)">
          {{ item.psl | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.prf`]="{ item }">
        <span :class="classIndicador(item.prf, item.metas.prf != undefined ? item.metas.prf : null)">
          {{ item.prf | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.qrt`]="{ item }">
        <span :class="classIndicador(item.qrt, item.metas.qrt != undefined ? item.metas.qrt : null)">
          {{ item.qrt | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.pcf`]="{ item }">
        <span :class="classIndicador(item.pcf, item.metas.pcf != undefined ? item.metas.pcf : null)">
          {{ item.pcf | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>
      <template v-slot:[`item.prp`]="{ item }">
        <span :class="classIndicador(item.prp, item.metas.prp != undefined ? item.metas.prp : null)">
          {{ item.prp | parseNumberToFloatBrIgnoringNaN }}
        </span>
      </template>

      <template v-slot:[`item.metas`]="{ item }">
        <v-btn @click="openMetasDialog(item)" min-width="0" class="px-1" fab icon x-small>
          <v-icon small>
            mdi-information-outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>


    <v-dialog v-model="metasDialog" max-width="720">
      <v-card>
        <v-card-title class="text-h5">
          <h4> {{ itemSelected.empresa.name }} - {{ itemSelected.competencia }} </h4>
        </v-card-title>
        <v-card-text>
          <v-simple-table class="table-indicadores" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Indicador</th>
                  <th>Valor</th>
                  <th>Meta</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="indicador in indicadores" :key="indicador.value">
                  <v-tooltip bottom v-if="traducaoIndicadores[indicador] != null">
                    <template v-slot:activator="{ on }">
                      <td v-on="on">
                        {{ indicador.text | toUpperCase }}
                      </td>
                    </template>
                    <span>{{ traducaoIndicadores[indicador.value] | toUpperCase }}</span>
                  </v-tooltip>
                  <td v-else>
                    {{ indicador.text | toUpperCase }}
                  </td>
                  <td>
                    {{
                          (itemSelected.indicadores[indicador.value] != null || itemSelected.indicadores[indicador.value] != undefined
                          ? itemSelected.indicadores[indicador.value]: '-') 
                          | parseNumberToFloatBrIgnoringNaN
                    }}
                  </td>
                  <td>
                    {{
                          itemSelected.metas[indicador.value] === undefined ? '-' : itemSelected.metas[indicador.value] | parseNumberToFloatBrIgnoringNaN
                    }}
                  </td>
                  <td>
                    <ArrowMetaDashboard 
                    :indicador="itemSelected.indicadores[indicador.value] != null || itemSelected.indicadores[indicador.value] != undefined
                              ? itemSelected.indicadores[indicador.value] : null"
                      :meta="itemSelected.metas[indicador.value] != undefined ? itemSelected.metas[indicador.value] : null" />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="metasDialog = false">
            Fechar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </span>
</template>

<script>

import DashboardFaturamentoService from "@/services/DashboardFaturamentoService";
import ArrowMetaDashboard from "../dashboardLno/ArrowMetaDashboard.vue";

export default {
  name: "DashboardFaturamentoGeral",
  components: { 'ArrowMetaDashboard': ArrowMetaDashboard },
  props: {
    competenciaDe: {
      type: String,
      required: true,
    },
    competenciaAte: {
      type: String,
      required: true,
    },
    activatedTab: {
      type: Number,
      required: true,
    },
    traducaoIndicadores: {
      type: Object,
      default: {}
    },
    metasDialog: false,

  },
  data: () => ({
    indicadores: [
      { text: 'QRT', value: "qrt" },
      { text: 'PRP', value: "prp" },
      { text: 'PSL', value: "psl" },
      { text: 'PRF', value: "prf" },
      { text: 'PCF', value: "pcf" },

    ],
    companies: [],
    dados: {},
    itemSelected: {
      indicadores: [],
      metas: [],
      competencia: '',
      empresa: {
        acronym: '',
        name: ''
      }
    }
  }),
  computed: {
    kpis() {
      return this.dados.length > 0 ? Object.keys(this.dados[0]) : [];
    },
    headers() {
      let headers = [

      ];
      this.kpis.forEach(kpi => {
        let name = this.indicadores.find((element) => element.value == kpi)

        headers.push({
          text: name != undefined ? name.text.toUpperCase() : kpi.toUpperCase(),
          value: kpi.toLowerCase()
        });
      });
      return headers;
    },
    items() {
      let items = [];
      if (this.dados.length > 0) {
        items = this.dados
      }
      return items;
    }
  },
  mounted() {
    this.getDados();
  },
  methods: {
    getDados() {
      // Apenas atualiza os dados se for a visão selecionada
      if (this.activatedTab !== 0) return;

      this.$emit("updateLoadingDados", true);

      DashboardFaturamentoService.getKpisTotalizadosTodasEmpresas(
        this.competenciaDe, this.competenciaAte,
      )
        .then(res => {
          this.dados = res;
        })
        .catch(err => {
          console.error(err);
          this.$toast.error('Erro recuperar dados da visão Geral.', '', { position: 'topRight' })
        })
        .finally(() => this.$emit("updateLoadingDados", false));
    },
    classIndicador(indicador, meta) {
      let classes = [];

      if(indicador != null && meta != null){
        if (indicador > meta) {
          classes.push('success--text font-weight-bold')
        } else if (indicador < meta) {
          classes.push('error--text font-weight-bold')
        }
      }
      return classes
    },
    openMetasDialog(linha) {

      this.itemSelected.indicadores = {
        'psl': linha.psl,
        'prf': linha.prf,
        'qrt': linha.qrt,
        'pcf': linha.pcf,
        'prp': linha.prp,
      }
      this.itemSelected.metas = linha.metas;
      this.itemSelected.empresa = linha.empresa;
      this.itemSelected.competencia = linha.competencia
      this.metasDialog = true;
    }

  },
  watch: {
    activatedTab() {
      this.getDados();
    },
    competenciaDe() {
      this.getDados();
    },
    competenciaAte() {
      this.getDados();
    },
  }
}

</script>

<style>
.table-indicadores table tbody tr td {
  font-size: 12px;
  padding: 0px 8px !important;
  text-align: end;
}

.table-indicadores table thead tr th {
  font-size: 12px;
  height: 25px;
  text-align: end !important;
  font-weight: 500;
}

.v-card-title-icon {
  position: relative;
  top: -40px;
  transition: .5s ease;
}
</style>