<template>
 <div>
    <v-icon v-if="(indicador > meta) && meta != null && indicador != null" color="success" small>
        mdi-arrow-top-right-thick
    </v-icon>
    <v-icon v-else-if="(indicador < meta) && meta != null && indicador != null" color="error"
        small>
        mdi-arrow-bottom-right-thick
    </v-icon>
    <v-icon v-else small>
        mdi-minus-thick
    </v-icon>
    
 </div>

</template>

<script>
export default {
    name: "ArrowMetaDashboard",
    props: {
        indicador: {
            type: Number,
            required: true,
        },
        meta: {
            type: Number,
            required: true,
        },

    },
}
</script>